import React from "react"
import Layout from "../components/layout"
import Item from "../components/item"
import SEO from "../components/seo"
import ERROR from "../components/error"
import Style from "../styles/main.module.css"
import FriendLink from "../components/friendlink"

const Imgs = (props) => (
    <div className={Style.box}>
    {
        props.imgs.map((item, index) => (
            <Item
                key={index}
                itemId={item.id}
                itemTitle={item.title}
                itemSrc={item.img}
                itemLen={item.num}
            />
        ))
    }
    </div>
)

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.ids = [];
        this.state = {
            list: []
        }
    }

    componentDidMount() {
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            let value = localStorage.getItem(key);
            let list = this.state.list;
            list.push(JSON.parse(value));
            this.setState({
                list:list
            })
            
        }
    }
    
    render() {
        return (
            <Layout>
                <SEO title="图片列表" />
                {
                    this.state.list.length < 1 ? <ERROR 
                    itemTitle='这里空空的什么也没有~' 
                    itemInfo="赶快去图片页点击右下角的心标收藏你喜欢的图片吧" 
                    itemNote="马上去收集图片" /> 
                    : 
                    <Imgs imgs={this.state.list}/>

                }
                <button className={Style.goTop} onClick={() => (document.body.scrollTop = document.documentElement.scrollTop = 0)}>&nbsp;</button>
                <a href="/" className={Style.goHome}>&nbsp;</a>
                <FriendLink urlType="global"  />
            </Layout>
        )
    }
}

export default Index

